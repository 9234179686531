var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('app-data-table',{attrs:{"busy":_vm.organizationAircraftsLoading,"manual-pagination":"","top-table":{
      showAdd: _vm.$can('ORGANIZATION_ACCOUNT_AIRCRAFT_ADD'),
      showSearch: true,
      searchFilterOptions: _vm.searchFieldOptions,
      searchText: _vm.search,
    },"actions-table":{
      showDuplicate: _vm.$can('ORGANIZATION_ACCOUNT_AIRCRAFT_ADD'),
      showEdit: _vm.$can('ORGANIZATION_ACCOUNT_AIRCRAFT_EDIT'), // && item.boundToTrip === false, // Issue MFC2-562, demande de modification même si l'avion est utilisé dans un vol
      showDelete: _vm.$can('ORGANIZATION_ACCOUNT_AIRCRAFT_DELETE'),
      showShow: _vm.$can('ORGANIZATION_ACCOUNT_AIRCRAFT_VIEW'),
    },"bottom-table":{ totalCount: _vm.pagination.totalItems },"fields":_vm.organizationAircraftsFields,"items":_vm.organizationAircrafts,"page":_vm.queryParams.page,"per-page":_vm.queryParams.numberOfItemsPerPage,"small":_vm.$store.getters['app/lgAndDown'],"sort-by":_vm.sort.sortBy,"sort-desc":_vm.sort.sortDesc,"table-name":"organization-aircrafts-table"},on:{"update:sortBy":function($event){return _vm.$set(_vm.sort, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sort, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},"table-search-filter":function($event){_vm.searchField = $event},"table-search-text":function($event){;(_vm.search = $event), _vm.fetchOrganizationAircrafts(true)},"table-add":function($event){return _vm.$router.push({ name: 'organization-aircraft-add' })},"delete":function($event){return _vm.deleteOrganizationAircraft($event.item)},"duplicate":function($event){return _vm.$router.push({
        name: 'organization-aircraft-duplicate',
        params: { organization_aircraft_id: $event.item.id },
      })},"edit":function($event){return _vm.$router.push({
        name: 'organization-aircraft-edit',
        params: { organization_aircraft_id: $event.item.id },
      })},"show":function($event){return _vm.$router.push({
        name: 'organization-aircraft-show',
        params: { organization_aircraft_id: $event.item.id },
      })},"table-select-per-page":function($event){_vm.queryParams.numberOfItemsPerPage = $event},"pagination":function($event){_vm.queryParams.page = $event}},scopedSlots:_vm._u([{key:"cell(aircraft)",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.name || item.aircraft.name)+" ")]}},{key:"cell(homeBase)",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.homeBase.name)+" ")]}},{key:"cell(active)",fn:function(ref){
      var item = ref.item;
return [(item.active)?_c('b-badge',{attrs:{"pill":"","variant":"light-success"}},[_vm._v(" "+_vm._s(_vm.$t('common.active'))+" ")]):_c('b-badge',{attrs:{"pill":"","variant":"light-danger"}},[_vm._v(" "+_vm._s(_vm.$t('common.inactive'))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }