<template>
  <b-card class="mb-0" no-body>
    <app-data-table
      :busy="organizationAircraftsLoading"
      manual-pagination
      :top-table="{
        showAdd: $can('ORGANIZATION_ACCOUNT_AIRCRAFT_ADD'),
        showSearch: true,
        searchFilterOptions: searchFieldOptions,
        searchText: search,
      }"
      :actions-table="{
        showDuplicate: $can('ORGANIZATION_ACCOUNT_AIRCRAFT_ADD'),
        showEdit: $can('ORGANIZATION_ACCOUNT_AIRCRAFT_EDIT'), // && item.boundToTrip === false, // Issue MFC2-562, demande de modification même si l'avion est utilisé dans un vol
        showDelete: $can('ORGANIZATION_ACCOUNT_AIRCRAFT_DELETE'),
        showShow: $can('ORGANIZATION_ACCOUNT_AIRCRAFT_VIEW'),
      }"
      :bottom-table="{ totalCount: pagination.totalItems }"
      :fields="organizationAircraftsFields"
      :items="organizationAircrafts"
      :page="queryParams.page"
      :per-page="queryParams.numberOfItemsPerPage"
      :small="$store.getters['app/lgAndDown']"
      :sort-by.sync="sort.sortBy"
      :sort-desc.sync="sort.sortDesc"
      table-name="organization-aircrafts-table"
      @table-search-filter="searchField = $event"
      @table-search-text=";(search = $event), fetchOrganizationAircrafts(true)"
      @table-add="$router.push({ name: 'organization-aircraft-add' })"
      @delete="deleteOrganizationAircraft($event.item)"
      @duplicate="
        $router.push({
          name: 'organization-aircraft-duplicate',
          params: { organization_aircraft_id: $event.item.id },
        })
      "
      @edit="
        $router.push({
          name: 'organization-aircraft-edit',
          params: { organization_aircraft_id: $event.item.id },
        })
      "
      @show="
        $router.push({
          name: 'organization-aircraft-show',
          params: { organization_aircraft_id: $event.item.id },
        })
      "
      @table-select-per-page="queryParams.numberOfItemsPerPage = $event"
      @pagination="queryParams.page = $event"
    >
      <!-- name -->
      <template #cell(aircraft)="{ item }">
        {{ item.name || item.aircraft.name }}
      </template>
      <!--homeBase-->
      <template #cell(homeBase)="{ item }">
        {{ item.homeBase.name }}
      </template>
      <!--status-->
      <template #cell(active)="{ item }">
        <b-badge v-if="item.active" pill variant="light-success">
          {{ $t('common.active') }}
        </b-badge>
        <b-badge v-else pill variant="light-danger">
          {{ $t('common.inactive') }}
        </b-badge>
      </template>
    </app-data-table>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import UIHelper from '@/helpers/ui'
import { deleteOrganizationAircraftRequest } from '@/request/globalApi/requests/organizationAircraftRequests'
import AppDataTable from '@/components/AppDataTable.vue'

export default {
  name: 'OrganizationAircrafts',

  components: {
    AppDataTable,
  },

  mixins: [UIHelper],
  data() {
    return {
      organizationAircraftsFields: [
        { key: 'aircraft', label: this.$t('aircraft.aircraft') },
        { key: 'type', label: this.$t('aircraft.type') },
        { key: 'registration', label: this.$t('aircraft.registration'), sortable: true },
        { key: 'callSign', label: this.$t('aircraft.call_sign') },
        { key: 'homeBase', label: this.$t('aircraft.homebase'), sortable: true },
        { key: 'aoc', label: this.$t('aircraft.aoc'), sortable: true },
        { key: 'active', label: this.$t('common.active'), sortable: true },
      ],
      sort: {
        sortBy: null,
        sortDesc: null,
      },
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
    ...mapState('organizationAircraft', ['organizationAircrafts', 'organizationAircraftsLoading', 'pagination', 'queryParams', 'sorting', 'searchText']),
    ...mapState('appConfig', ['dataTable']),
    ...mapFields('organizationAircraft', [
      'queryParams.page',
      'queryParams.numberOfItemsPerPage',
      'queryParams.nameOrder',
      'queryParams.categoryOrder',
      'queryParams.registrationOrder',
      'queryParams.aocOrder',
      'queryParams.statusOrder',
      'searchField',
    ]),
    ...mapGetters('organizationAircraft', ['searchFieldOptions']),
    search: {
      get() {
        return this.searchText
      },
      set(value) {
        this.setSearch(value)
      },
    },
  },
  watch: {
    queryParams: {
      handler() {
        this.fetchOrganizationAircrafts(true)
      },
      deep: true,
    },

    sort: {
      handler(value) {
        this.setSort(value)
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchOrganizationAircrafts(true)
  },
  methods: {
    ...mapActions('organizationAircraft', ['fetchOrganizationAircrafts', 'clearOrganizationAircraftsShowed', 'setSort', 'setSearch']),

    deleteOrganizationAircraft(aircraft) {
      deleteOrganizationAircraftRequest(this.selectedOrganization.id, aircraft.id).then(response => {
        response && this.fetchOrganizationAircrafts(true)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

@media (min-width: 992px) {
  .input-group-prepend .select-search {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media (max-width: 992px) {
  .organizationAircraftAdd,
  .input-group-prepend,
  .per-page-selector {
    width: 100%;
  }

  .input-group-prepend {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group-prepend input {
    border-top-left-radius: 0;
  }

  .input-group-append button {
    border-top-right-radius: 0;
  }
}
</style>
